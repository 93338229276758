
const checkConsent = () => {
    window.addEventListener('CookiebotOnAccept', function (e) {
        if (Cookiebot.consent.marketing){
            $.ajax({
                url: window.enhancedConversionsUrl,
                type: 'get',
                dataType: 'json',
                success(data) {
                    if (data && data.customerDetails) {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push(data.customerDetails);
                    }
                },
            });
            }
        }, false);
};

function init () {
    checkConsent();
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', () => {
        init();
    }, false);

} else {
    init();
}
